import * as types from './Business.actionTypes';

export const initialize = () => {
  return {
    type: types.INITIALIZE,
  };
};

export const setLoading = (value) => {
  return {
    type: types.SET_LOADING,
    value,
  };
};

export const clear = () => {
  return {
    type: types.CLEAR,
  };
};

export const getBusinessData = (callback) => {
  return {
    type: types.GET_BUSINESS_DATA,
    callback,
  };
};

export const setBusinessData = (data) => {
  return {
    type: types.SET_BUSINESS_DATA,
    data,
  };
};

export const getBranches = () => {
  return {
    type: types.GET_BRANCHES,
  };
};

export const setBranches = (data) => {
  return {
    type: types.SET_BRANCHES,
    data,
  };
};

export const getBranchAddress = (branchTraceId, callback) => {
  return {
    type: types.GET_BRANCH_ADDRESS,
    branchTraceId,
    callback,
  };
};

export const setBranchesWithNoPaymentMethod = (data) => {
  return {
    type: types.SET_BRANCHES_WITH_NO_PAYMENT_METHOD,
    data,
  };
};

export const getBranchesWithNoPaymentMethod = () => {
  return {
    type: types.GET_BRANCHES_WITH_NO_PAYMENT_METHOD,
  };
};

export const getSummary = (branchId, columnOrder, columnOrderType) => {
  return {
    type: types.GET_SUMMARY,
    branchId,
    columnOrder,
    columnOrderType,
  };
};

export const setSummary = (data) => {
  return {
    type: types.SET_SUMMARY,
    data,
  };
};

export const getPaymentSchedule = (branchId, month, year) => {
  return {
    type: types.GET_PAYMENT_SCHEDULE,
    branchId,
    month,
    year,
  };
};

export const setPaymentSchedule = (data) => {
  return {
    type: types.SET_PAYMENT_SCHEDULE,
    data,
  };
};

export const getPaymentDetailsByDay = (branchId, start, end, page, columnOrder, columnOrderType, callback) => {
  return {
    type: types.GET_PAYMENT_DETAILS_BY_DAY,
    branchId,
    start,
    end,
    page,
    columnOrder,
    columnOrderType,
    callback,
  };
};

export const getOperationDetails = (operationId, callback) => {
  return {
    type: types.GET_OPERATION_DETAILS,
    operationId,
    callback,
  };
};

export const getActivity = (params) => {
  return {
    type: types.GET_ACTIVITY,
    params,
  };
};

export const setActivity = (data) => {
  return {
    type: types.SET_ACTIVITY,
    data,
  };
};

export const getReversals = (branchId, startDate, endDate, page, columnOrder, columnOrderType) => {
  return {
    type: types.GET_REVERSALS,
    branchId,
    startDate,
    endDate,
    page,
    columnOrder,
    columnOrderType,
  };
};

export const setReversals = (data) => {
  return {
    type: types.SET_REVERSALS,
    data,
  };
};

export const setPaymentPlan = (paymentPlan) => {
  return {
    type: types.SET_PAYMENT_PLAN,
    paymentPlan,
  };
};

export const getVouchers = (
  branchId,
  startDate,
  endDate,
  voucherType,
  page,
  count,
  columnOrder,
  columnOrderType,
  callback
) => {
  return {
    type: types.GET_VOUCHERS,
    branchId,
    startDate,
    endDate,
    voucherType,
    page,
    count,
    columnOrder,
    columnOrderType,
    callback,
  };
};

export const getHistory = (branchId, startDate, endDate, page, count, columnOrder, columnOrderType, callback) => {
  return {
    type: types.GET_HISTORY,
    branchId,
    startDate,
    endDate,
    page,
    count,
    columnOrder,
    columnOrderType,
    callback,
  };
};

export const setHistory = (data) => {
  return {
    type: types.SET_HISTORY,
    data,
  };
};

export const getLoans = () => {
  return {
    type: types.GET_LOANS,
  };
};

export const setLoans = (data) => {
  return {
    type: types.SET_LOANS,
    data,
  };
};

export const checkLoansAvailability = () => {
  return {
    type: types.CHECK_LOANS_AVAILABILITY,
  };
};

export const setLoanAvailability = (data) => {
  return {
    type: types.SET_LOAN_AVAILABILITY,
    data,
  };
};

export const getLoanLink = (callback) => {
  return {
    type: types.GET_LOAN_LINK,
    callback,
  };
};

export const exportTable = (exportType, params, callback) => {
  return {
    type: types.EXPORT_TABLE,
    exportType,
    params,
    callback,
  };
};

export const getPaymentLinks = (status, page, searchQuery) => {
  return {
    type: types.GET_PAYMENT_LINKS,
    status,
    page,
    searchQuery,
  };
};

export const setPaymentLinks = (data) => {
  return {
    type: types.SET_PAYMENT_LINKS,
    data,
  };
};

export const createPaymentLink = (branchSecret, params, callback) => {
  return {
    type: types.CREATE_PAYMENT_LINK,
    branchSecret,
    params,
    callback,
  };
};

export const deletePaymentLink = (traceId, callback) => {
  return {
    type: types.DELETE_PAYMENT_LINK,
    traceId,
    callback,
  };
};

export const getPaymentLink = (traceId, callback) => {
  return {
    type: types.GET_PAYMENT_LINK,
    traceId,
    callback,
  };
};

export const getPaymentLinkTransactions = (traceId, startDate, endDate, page, pageSize, callback) => {
  return {
    type: types.GET_PAYMENT_LINK_TRANSACTIONS,
    traceId,
    startDate,
    endDate,
    page,
    pageSize,
    callback,
  };
};

export const getPaymentLinkRestrictions = (traceId, callback) => {
  return {
    type: types.GET_PAYMENT_LINK_RESTRICTIONS,
    traceId,
    callback,
  };
};

export const exportPaymentLinks = (status, searchQuery, callback) => {
  return {
    type: types.EXPORT_PAYMENT_LINKS,
    status,
    searchQuery,
    callback,
  };
};

export const getSecrets = (returnSecrets = true, callback) => {
  return {
    type: types.GET_SECRETS,
    returnSecrets,
    callback,
  };
};

export const getBranchSecret = (traceId, callback) => {
  return {
    type: types.GET_BRANCH_SECRET,
    traceId,
    callback,
  };
};

export const checkForActiveServices = (ids, callback) => {
  return {
    type: types.CHECK_FOR_ACTIVE_SERVICES,
    ids,
    callback,
  };
};

export const getMobileBalanceStatus = (callback) => {
  return {
    type: types.GET_MOBILE_BALANCE_STATUS,
    callback,
  };
};

export const getMobileBalance = (callback) => {
  return {
    type: types.GET_MOBILE_BALANCE,
    callback,
  };
};

export const getMobileBalanceActivePos = (callback) => {
  return {
    type: types.GET_MOBILE_BALANCE_ACTIVE_POS,
    callback,
  };
};

export const getMobileBalanceHistory = (
  startDate,
  endDate,
  phoneCompany,
  pos,
  amountSearch,
  phoneDigitsSearch,
  page,
  pageSize,
  callback
) => {
  return {
    type: types.GET_MOBILE_BALANCE_HISTORY,
    callback,
    startDate,
    endDate,
    phoneCompany,
    pos,
    amountSearch,
    phoneDigitsSearch,
    page,
    pageSize,
  };
};

export const getAccounts = (page, callback) => {
  return {
    type: types.GET_ACCOUNTS,
    page,
    callback,
  };
};

export const getAccountDetails = (accountTraceId, startDate, endDate, sign, page, view, callback) => {
  return {
    type: types.GET_ACCOUNT_DETAILS,
    accountTraceId,
    sign,
    startDate,
    endDate,
    page,
    view,
    callback,
  };
};

export const getPosAliases = (callback) => {
  return {
    type: types.GET_POS_ALIASES,
    callback,
  };
};

export const getPhoneCompanies = (callback) => {
  return {
    type: types.GET_PHONE_COMPANIES,
    callback,
  };
};

export const getItauBranches = (callback) => {
  return {
    type: types.GET_ITAU_BRANCHES,
    callback,
  };
};

export const getBranchIssuers = (branchId, callback) => {
  return {
    type: types.GET_BRANCH_ISSUERS,
    branchId,
    callback,
  };
};

export const getItauSales = (branchId, startDate, endDate, page, callback) => {
  return {
    type: types.GET_ITAU_SALES,
    branchId,
    startDate,
    endDate,
    page,
    callback,
  };
};

export const makeItauSale = (traceId, form, callback) => {
  return {
    type: types.MAKE_ITAU_SALE,
    traceId,
    form,
    callback,
  };
};

export const getTransactionsForHome = (startDate, endDate, callback) => {
  return {
    type: types.GET_TRANSACTIONS_FOR_HOME,
    startDate,
    endDate,
    callback,
  };
};

export const getHistoryForHome = (startDate, endDate, callback) => {
  return {
    type: types.GET_HISTORY_FOR_HOME,
    startDate,
    endDate,
    callback,
  };
};

export const getBalancesForHome = (todayDate, callback) => {
  return {
    type: types.GET_BALANCES_FOR_HOME,
    todayDate,
    callback,
  };
};

export const getProviderStatus = (callback) => {
  return {
    type: types.GET_PROVIDER_STATUS,
    callback,
  };
};

export const getProviders = (callback) => {
  return {
    type: types.GET_PROVIDERS,
    callback,
  };
};

export const getServices = (providerTraceId, callback) => {
  return {
    type: types.GET_SERVICES,
    providerTraceId,
    callback,
  };
};

export const addSubscription = (data, callback) => {
  return {
    type: types.ADD_SUBSCRIPTION,
    data,
    callback,
  };
};

export const updateSubscription = (subscriptionTraceId, data, callback) => {
  return {
    type: types.UPDATE_SUBSCRIPTION,
    subscriptionTraceId,
    data,
    callback,
  };
};

export const getSubscriptions = (
  page,
  branchTraceId,
  providerTraceId,
  productTraceId,
  createdAtFrom,
  createdAtTo,
  isActive,
  callback
) => {
  return {
    type: types.GET_SUBSCRIPTIONS,
    page,
    branchTraceId,
    providerTraceId,
    productTraceId,
    createdAtFrom,
    createdAtTo,
    isActive,
    callback,
  };
};

export const getSubscription = (subscriptionTraceId, callback) => {
  return {
    type: types.GET_SUBSCRIPTION,
    subscriptionTraceId,
    callback,
  };
};

export const cancelSubscription = (subscriptionTraceId, callback) => {
  return {
    type: types.CANCEL_SUBSCRIPTION,
    subscriptionTraceId,
    callback,
  };
};

export const getPendingPayments = (
  branchTraceId,
  subscriptionTraceId,
  orderByField,
  orderByDesc,
  page,
  chargeRequestDateFrom,
  chargeRequestDateTo,
  providerTraceId,
  productTraceId,
  callback
) => {
  return {
    type: types.GET_PENDING_PAYMENTS,
    branchTraceId,
    subscriptionTraceId,
    orderByField,
    orderByDesc,
    page,
    chargeRequestDateFrom,
    chargeRequestDateTo,
    providerTraceId,
    productTraceId,
    callback,
  };
};

export const getPaymentsHistory = (
  branchTraceId,
  subscriptionTraceId,
  orderByField,
  orderByDesc,
  page,
  chargeRequestDateFrom,
  chargeRequestDateTo,
  providerTraceId,
  productTraceId,
  status,
  callback
) => {
  return {
    type: types.GET_PAYMENTS_HISTORY,
    branchTraceId,
    subscriptionTraceId,
    orderByField,
    orderByDesc,
    page,
    chargeRequestDateFrom,
    chargeRequestDateTo,
    providerTraceId,
    productTraceId,
    status,
    callback,
  };
};

export const subscriptionApprovePayment = (subscriptionTraceId, callback) => {
  return {
    type: types.SUBSCRIPTION_APPROVE_PAYMENT,
    subscriptionTraceId,
    callback,
  };
};

export const subscriptionRejectPayment = (subscriptionTraceId, callback) => {
  return {
    type: types.SUBSCRIPTION_REJECT_PAYMENT,
    subscriptionTraceId,
    callback,
  };
};

export const getSubscriptionPayment = (paymentTraceId, callback) => {
  return {
    type: types.GET_SUBSCRIPTION_PAYMENT,
    paymentTraceId,
    callback,
  };
};

export const getProducts = (branchTraceId, productTraceId, isActive, page, callback) => {
  return {
    type: types.GET_PRODUCTS,
    branchTraceId,
    productTraceId,
    isActive,
    page,
    callback,
  };
};

export const getProduct = (productTraceId, callback) => {
  return {
    type: types.GET_PRODUCT,
    productTraceId,
    callback,
  };
};

export const updateProduct = (productTraceId, data, callback) => {
  return {
    type: types.UPDATE_PRODUCT,
    productTraceId,
    data,
    callback,
  };
};

export const getSubscribers = (page, createdAtFrom, createdAtTo, productTraceId, isActive, merchantName, merchantDocument, callback) => {
  return {
    type: types.GET_SUBSCRIBERS,
    page,
    createdAtFrom,
    createdAtTo,
    productTraceId,
    isActive,
    merchantName,
    merchantDocument,
    callback,
  };
};

export const getSubscriber = (subscriberTraceId, callback) => {
  return {
    type: types.GET_SUBSCRIBER,
    subscriberTraceId,
    callback,
  };
};

export const getPendingCharges = (
  subscriptionTraceId,
  productTraceId,
  orderByField,
  orderByDesc,
  page,
  chargeRequestDateFrom,
  chargeRequestDateTo,
  merchantName,
  callback
) => {
  return {
    type: types.GET_PENDING_CHARGES,
    subscriptionTraceId,
    productTraceId,
    orderByField,
    orderByDesc,
    page,
    chargeRequestDateFrom,
    chargeRequestDateTo,
    merchantName,
    callback,
  };
};

export const getChargesHistory = (
  subscriptionTraceId,
  productTraceId,
  orderByField,
  orderByDesc,
  page,
  chargeRequestDateFrom,
  chargeRequestDateTo,
  merchantName,
  status,
  callback
) => {
  return {
    type: types.GET_CHARGES_HISTORY,
    subscriptionTraceId,
    productTraceId,
    orderByField,
    orderByDesc,
    page,
    chargeRequestDateFrom,
    chargeRequestDateTo,
    merchantName,
    status,
    callback,
  };
};

export const addCharge = (data, additionalData, callback) => {
  return {
    type: types.ADD_CHARGE,
    data,
    additionalData,
    callback,
  };
};

export const getCharge = (chargeTraceId, callback) => {
  return {
    type: types.GET_CHARGE,
    chargeTraceId,
    callback,
  };
};

export const retryCharge = (messageId, callback) => {
  return {
    type: types.RETRY_CHARGE,
    messageId,
    callback,
  };
};

export const cancelCharge = (chargeTraceId, callback) => {
  return {
    type: types.CANCEL_CHARGE,
    chargeTraceId,
    callback,
  };
};

export const getProvidersSubscriptionsMerchant = (callback) => {
  return {
    type: types.GET_PROVIDERS_SUBSCRIPTIONS_MERCHANT,
    callback,
  };
};

export const getSubscriptionProviders = (callback) => {
  return {
    type: types.GET_SUBSCRIPTION_PROVIDERS,
    callback,
  };
};

export const getSubscriptionServices = (callback) => {
  return {
    type: types.GET_SUBSCRIPTION_SERVICES,
    callback,
  };
};

export const getProviderServices = (callback) => {
  return {
    type: types.GET_PROVIDER_SERVICES,
    callback,
  };
};

export const getProvidersSubscriptionsPlans = (
  branchTraceId,
  subscriptionPlanStatus,
  subscriptionPlanType = 'TRADITIONAL',
  currency,
  planName,
  count = 15,
  page,
  callback
) => {
  return {
    type: types.GET_PROVIDERS_SUBSCRIPTIONS_PLANS,
    branchTraceId,
    subscriptionPlanStatus,
    subscriptionPlanType,
    currency,
    planName,
    count,
    page,
    callback,
  };
};

export const toggleProvidersSubscriptionsPlanStatus = (subscriptionPlanTraceId, subscriptionPlanStatus, callback) => {
  return {
    type: types.TOGGLE_PROVIDERS_SUBSCRIPTIONS_PLAN_STATUS,
    subscriptionPlanTraceId,
    subscriptionPlanStatus,
    callback,
  };
};

export const getProvidersSubscriptionsPlanShareLink = (subscriptionPlanTraceId, callback) => {
  return {
    type: types.GET_PROVIDERS_SUBSCRIPTIONS_PLAN_SHARE_LINK,
    subscriptionPlanTraceId,
    callback,
  };
};

export const providersSubscriptionsAddPlan = (data, callback) => {
  return {
    type: types.PROVIDERS_SUBSCRIPTIONS_ADD_PLAN,
    data,
    callback,
  };
};

export const providersSubscriptionsUpdatePlan = (subscriptionPlanTraceId, data, callback) => {
  return {
    type: types.PROVIDERS_SUBSCRIPTIONS_UPDATE_PLAN,
    subscriptionPlanTraceId,
    data,
    callback,
  };
};

export const getProvidersSubscriptionsPlan = (subscriptionPlanTraceId, callback) => {
  return {
    type: types.GET_PROVIDERS_SUBSCRIPTIONS_PLAN,
    subscriptionPlanTraceId,
    callback,
  };
};

export const getProvidersSubscriptionsSubscriptions = (
  branchTraceId,
  subscriberTraceId,
  subscriptionPlanTraceId,
  subscriptionStatus,
  fromDate,
  untilDate,
  page,
  count,
  callback
) => {
  return {
    type: types.GET_PROVIDERS_SUBSCRIPTIONS_SUBSCRIPTIONS,
    branchTraceId,
    subscriberTraceId,
    subscriptionPlanTraceId,
    subscriptionStatus,
    fromDate,
    untilDate,
    page,
    count,
    callback,
  };
};

export const getProvidersSubscriptionsSubscribers = (page, count, callback) => {
  return {
    type: types.GET_PROVIDERS_SUBSCRIPTIONS_SUBSCRIBERS,
    page,
    count,
    callback,
  };
};

export const toggleProvidersSubscriptionsSubscriptionStatus = (subscriptionTraceId, status, callback) => {
  return {
    type: types.UPDATE_PROVIDERS_SUBSCRIPTIONS_SUBSCRIPTION_STATUS,
    subscriptionTraceId,
    status,
    callback,
  };
};

export const getProvidersSubscriptionsSubscription = (subscriptionTraceId, callback) => {
  return {
    type: types.GET_PROVIDERS_SUBSCRIPTIONS_SUBSCRIPTION,
    subscriptionTraceId,
    callback,
  };
};

export const getProvidersSubscriptionsTransactions = (
  transactionTraceId,
  subscriptionTraceId,
  transactionStatus,
  fromDate,
  untilDate,
  page,
  count = 15,
  callback
) => {
  return {
    type: types.GET_PROVIDERS_SUBSCRIPTIONS_TRANSACTIONS,
    transactionTraceId,
    subscriptionTraceId,
    transactionStatus,
    fromDate,
    untilDate,
    page,
    count,
    callback,
  };
};

export const updateProvidersSubscruptionsSubscriber = (subscriberId, data, callback) => {
  return {
    type: types.UPDATE_PROVIDERS_SUBSCRIPTIONS_SUBSCRIBER,
    subscriberId,
    data,
    callback,
  };
};

export const getProvidersSubscriptionsPromotions = (
  subscriptionPlanTraceId,
  promotionStatus,
  promotionType,
  appliesTo,
  name,
  count = 15,
  page,
  callback
) => {
  return {
    type: types.GET_PROVIDERS_SUBSCRIPTIONS_PROMOTIONS,
    subscriptionPlanTraceId,
    promotionStatus,
    promotionType,
    appliesTo,
    name,
    count,
    page,
    callback,
  };
};

export const updateProvidersSubscriptionsPromotion = (promotionTraceId, subscriptionPlanTraceId, promotionStatus, callback) => {
  return {
    type: types.UPDATE_PROVIDERS_SUBSCRIPTIONS_PROMOTION,
    promotionTraceId,
    subscriptionPlanTraceId,
    promotionStatus,
    callback,
  };
};

export const getProvidersSubscriptionsPromotion = (promotionTraceId, callback) => {
  return {
    type: types.GET_PROVIDERS_SUBSCRIPTIONS_PROMOTION,
    promotionTraceId,
    callback,
  };
};

export const addProvidersSubscriptionsPromotion = (subscriptionPlanTraceId, branchTraceId, payload, callback) => {
  return {
    type: types.ADD_PROVIDERS_SUBSCRIPTIONS_PROMOTION,
    subscriptionPlanTraceId,
    branchTraceId,
    payload,
    callback,
  };
};

export const getProvidersSubscriptionsSubscriptionShareLink = (subscriptionTraceId, callback) => {
  return {
    type: types.GET_PROVIDERS_SUBSCRIPTIONS_SUBSCRIPTION_SHARE,
    subscriptionTraceId,
    callback,
  };
};

export const getProvidersSubscriptionsTermsAndConditionsLink = (branchTraceId, callback) => {
  return {
    type: types.GET_PROVIDERS_SUBSCRIPTIONS_TERMS_AND_CONDITIONS_LINK,
    branchTraceId,
    callback,
  };
};

export const providersSubscriptionsAddTermsAndConditions = (branchTraceId, file, callback) => {
  return {
    type: types.PROVIDERS_SUBSCRIPTIONS_ADD_TERMS_AND_CONDITIONS,
    branchTraceId,
    file,
    callback,
  };
};

export const exportSubscriptions = (
  branchTraceId,
  page,
  createdAtFrom,
  createdAtTo,
  providerTraceId,
  productTraceId,
  isActive,
  callback
) => {
  return {
    type: types.EXPORT_SUBSCRIPTIONS,
    branchTraceId,
    page,
    createdAtFrom,
    createdAtTo,
    providerTraceId,
    productTraceId,
    isActive,
    callback,
  };
};

export const exportSubscriptionPayments = (
  branchTraceId,
  providerTraceId,
  orderByField,
  orderByDesc,
  page,
  chargeRequestDateFrom,
  chargeRequestDateTo,
  productTraceId,
  status,
  exportPendingCharges,
  isSubscription,
  callback
) => {
  return {
    type: types.EXPORT_SUBSCRIPTION_PAYMENTS,
    branchTraceId,
    providerTraceId,
    orderByField,
    orderByDesc,
    page,
    chargeRequestDateFrom,
    chargeRequestDateTo,
    productTraceId,
    status,
    exportPendingCharges,
    isSubscription,
    callback,
  };
};

export const exportProviderServices = (page, branchTraceId, productTraceId, isActive, callback) => {
  return {
    type: types.EXPORT_PROVIDER_SERVICES,
    page,
    branchTraceId,
    productTraceId,
    isActive,
    callback,
  };
};

export const exportProviderSubscribers = (
  page,
  productTraceId,
  createdAtFrom,
  createdAtTo,
  isActive,
  merchantName,
  callback
) => {
  return {
    type: types.EXPORT_PROVIDER_SUBSCRIBERS,
    page,
    productTraceId,
    createdAtFrom,
    createdAtTo,
    isActive,
    merchantName,
    callback,
  };
};

export const exportProviderCharges = (
  productTraceId,
  orderByField,
  orderByDesc,
  page,
  chargeRequestDateFrom,
  chargeRequestDateTo,
  merchantName,
  status,
  pendingCharges,
  callback
) => {
  return {
    type: types.EXPORT_PROVIDER_CHARGES,
    page,
    orderByField,
    orderByDesc,
    productTraceId,
    chargeRequestDateFrom,
    chargeRequestDateTo,
    merchantName,
    status,
    pendingCharges,
    callback,
  };
};

export const exportProvidersSubscriptionsPlans = (
  branchTraceId,
  subscriptionPlanStatus,
  subscriptionPlanType = 'TRADITIONAL',
  currency,
  planName,
  page,
  callback
) => {
  return {
    type: types.EXPORT_PROVIDERS_SUBSCRIPTIONS_PLANS,
    branchTraceId,
    subscriptionPlanStatus,
    subscriptionPlanType,
    currency,
    planName,
    page,
    callback,
  };
};

export const exportProvidersSubscriptionsSubscriptions = (
  branchTraceId,
  subscriberTraceId,
  subscriptionPlanTraceId,
  subscriptionStatus,
  fromDate,
  untilDate,
  page,
  callback
) => {
  return {
    type: types.EXPORT_PROVIDERS_SUBSCRIPTIONS_SUBSCRIPTIONS,
    branchTraceId,
    subscriberTraceId,
    subscriptionPlanTraceId,
    subscriptionStatus,
    fromDate,
    untilDate,
    page,
    callback,
  };
};

export const exportProvidersSubscriptionsTransactions = (
  subscriptionTraceId,
  transactionStatus,
  fromDate,
  untilDate,
  page,
  count,
  callback
) => {
  return {
    type: types.EXPORT_PROVIDERS_SUBSCRIPTIONS_TRANSACTIONS,
    subscriptionTraceId,
    transactionStatus,
    fromDate,
    untilDate,
    page,
    count,
    callback,
  };
};

export const exportProvidersSubscriptionsPromotions = (
  subscriptionPlanTraceId,
  promotionStatus,
  promotionType,
  name,
  count = 15,
  page,
  callback
) => {
  return {
    type: types.EXPORT_PROVIDERS_SUBSCRIPTIONS_PROMOTIONS,
    subscriptionPlanTraceId,
    promotionStatus,
    promotionType,
    name,
    count,
    page,
    callback,
  };
};
